.profile-dropdown {
  position: absolute;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  list-style-type: none;
  padding: 20px 20px 10px 20px;
  position: absolute;
  top: 60px;
  right: 12px;
  background-color: white;
  min-width: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.hidden {
  display: none;
}

/* Navbar container */
.navbar {
  background-color: #007BFF;
  /* Background color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  /* Padding for the navbar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Box shadow for a subtle elevation effect */
}

/* Navbar links */
.navbar a {
  text-decoration: none;
  /* Remove underlines from links */
  color: #fff;
  /* Text color */
  font-weight: bold;
  font-size: 2.5rem;
  margin-right: 20px;
  /* Spacing between links */
  transition: color 0.3s;
  /* Smooth color transition on hover */
}

/* Navbar links on hover */
.navbar a:hover {
  color: #0056b3;
  /* Change color on hover */
}

/* Profile button container */
.profile-button-container {
  display: flex;
  align-items: center;
}

/* Profile button (You can style it as needed) */
.profile-button {
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  font-size: 2rem;
  transition: background-color 0.3s, color 0.3s;
}

/* Profile button on hover */
.profile-button:hover {
  background-color: #0056b3;
  /* Change background color on hover */
  color: #fff;
  /* Change text color on hover */
}

/* Style for the container */
.user-info-container {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* Adjust the gap between list items */
  font-size: 16px;
  /* Adjust the font size */
}

/* Style for list items */
.user-info-container li {
  /* Add desired styles for list items */
  color: #333;
  /* Text color */
  font-weight: bold;
}

/* Style for the log-out button */
.logout-button {
  background-color: #007BFF;
  /* Button background color */
  color: #fff;
  /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  /* Adjust padding as needed */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Style for the log-out button on hover */
.logout-button:hover {
  background-color: #0056b3;
  /* Change background color on hover */
}

.navlinks {
  display: flex;
  flex-direction: column;
}

.navlinks > * {
  color: black;
}