/* -------------------- Single Patient Container Styles -------------------- */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-notes-section {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.patient-info {
  display: flex;
  align-items: baseline;
}

/* -------------------- Patient Info Styles -------------------- */
/* Style for the main container with increased space around the edges */
.patient-info-container {
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
}

/* Style for the patient name section */
.patient-name-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Style for the patient name */
.patient-name {
  font-size: 24px;
  font-weight: bold;
  width: 35rem;

}

/* Style for the patient status */
.patient-status {
  font-size: 16px;
  color: #007BFF;
}

/* Style for the patient DOB section */
.patient-dob-section {
  margin-bottom: 20px;
}

/* Style for the patient DOB text */
.patient-dob-text {
  font-size: 16px;
}

/* Style for the edit button */
.edit-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Add hover effect for the edit button */
.edit-button:hover {
  background-color: #0056b3;
}

/* -------------------- Address Styles -------------------- */

/* Style for the list item container */
.address-list-item {
  width: 100%; /* Make the address list items full-width on small screens */
  display: flex;
  flex-direction: column; /* Stack address items on small screens */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for the left side content */
.address-left {
  flex: 1;
}

/* Style for address details */
.address-details {
  margin-bottom: 10px;
}

/* Style for the green dot indicating current address */
.green-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #28a745;
  /* Green color */
  border-radius: 50%;
  margin-right: 5px;
}

/* Style for the red dot indicating non-current address */
.red-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #dc3545;
  /* Red color */
  border-radius: 50%;
  margin-right: 5px;
}

/* Style for the right side content */
.address-right,
.notes-right {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap on small screens */
  gap: 10px;
}

/* Style for buttons */
.address-button,
.notes-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Add hover effect for buttons */
.address-button:hover {
  background-color: #0056b3;
}

/* -------------------- Notes Styles -------------------- */

/* Style for the list item container */
.notes-list-item {
  width: 100%; /* Make the notes list items full-width on small screens */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for the top section (left and right) */
.notes-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Style for note title */
.note-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* Style for date */
.note-date {
  font-size: 12px;
  color: #666;
}

/* Style for note text */
.note-text {
  font-size: 14px;
  overflow-wrap: normal;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .address-list-item,
  .notes-list-item {
    width: 100%;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .address-notes-section {
    display: flex;
    flex-direction: column;
  }
}
